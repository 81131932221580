import React from 'react';
import PhoneInput, { Country } from 'react-phone-number-input/input';

type PhoneNumberInputProps = {
  className?: string;
  name: string;
  disabled?: boolean;
  error?: boolean;
  onBlur?: () => void;
  defaultCountry?: Country;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  validator?: any;
};

function PhoneNumberInput({
  className,
  name,
  disabled,
  error,
  onBlur,
  defaultCountry,
  placeholder,
  value,
  onChange,
  onKeyPress,
  validator,
}: PhoneNumberInputProps) {
  return (
    <div className="relative">
      {validator}

      <PhoneInput
        country="US"
        className={`${className} ${error ? 'border-river-red' : ''}`}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
        defaultCountry={defaultCountry}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </div>
  );
}

PhoneNumberInput.defaultProps = {
  className: '',
  defaultCountry: 'US',
  placeholder: '',
  disabled: false,
  error: false,
  onBlur: undefined,
  onKeyPress: undefined,
};

export default PhoneNumberInput;
