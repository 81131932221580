import React from 'react';
import { formatPhoneNumber } from 'react-phone-number-input';
import Modal from '../Modal';
import { Member } from '../../../types/types';

type ModalProps = {
  member: Member;
  onCloseModal: () => void;
};

function MemberDetails({ onCloseModal, member }: ModalProps) {
  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl w-full bg-white tablet:w-[500px] laptop:w-[600px] tablet:min-h-[400px]">
        <div className="flex flex-col px-6 tablet:px-12 py-10 items-center">
          <h3 className="font-semibold text-black uppercase text-lg leading-[28px] tracking-[0.03em] mb-3 text-center">
            Member Details
          </h3>

          <div className="flex flex-col space-y-2">
            <p>
              <strong>First name:</strong> {member.firstName}
            </p>
            <p>
              <strong>Last name:</strong> {member.lastName}
            </p>
            <p>
              <strong>Email:</strong> {member.email}
            </p>
            <p>
              <strong>Phone number:</strong> {formatPhoneNumber(member.phoneNumber)}
            </p>
            <p>
              <strong>Subscribed:</strong>{' '}
              <span
                className={`rounded-full px-3 py-1 text-[14px] tracking-normal font-normal ${
                  member.isSubscribed ? 'text-green-500 bg-[#E8F8EA]' : 'text-red-500 bg-[#FFE5E0]'
                }`}
              >
                {member.isSubscribed ? 'Yes' : 'No'}
              </span>
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default MemberDetails;
