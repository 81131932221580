import * as React from 'react';
import { callAllFns } from '../utils/utils';

const useDropdownTrigger = (triggerRef?: React.RefObject<HTMLElement>) => {
  const [isExpanded, setIsExpanded] = React.useState(false);

  const trigger = () => setIsExpanded(!isExpanded);

  function getTriggerProps({ onClick, ...props }: any = {}) {
    return {
      onClick: callAllFns(onClick, trigger),
      ...props,
    };
  }

  React.useEffect(() => {
    function listener(event: MouseEvent | TouchEvent) {
      if (triggerRef?.current) {
        const element = triggerRef.current;
        if (element && element.contains(event.target as Node)) {
          return;
        }
        setIsExpanded(false);
      }
    }

    if (isExpanded) {
      document.addEventListener('mousedown', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [triggerRef, isExpanded]);

  return { isExpanded, trigger, getTriggerProps };
};

export default useDropdownTrigger;
