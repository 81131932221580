import { QueryClient, useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Outlet, useLoaderData, useLocation, useNavigation } from 'react-router-dom';
import { BounceLoader } from 'react-spinners';
import { logout, showAlert, useAppContext } from '../../context/appContext';
import { authQuery } from '../../pages/Login/Login';
import { paths } from '../../routes';
import { AUTH_TOKEN_STORE_KEY, getCookie } from '../../utils/utils';
import Alert from '../Alert/Alert';
import Navbar from '../Navbar/Navbar';

export function loader(queryClient: QueryClient) {
  return async () => {
    let error = {};
    let data = {};

    try {
      data =
        queryClient.getQueryData(authQuery().queryKey) ??
        (await queryClient.fetchQuery(authQuery()));
    } catch (queryError: any) {
      error = queryError;
    }

    if (Object.keys(error).length) {
      return { error };
    }

    return { data };
  };
}

function Layout() {
  const [{ alert }, dispatch] = useAppContext();
  const token = getCookie(AUTH_TOKEN_STORE_KEY);

  const { data } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;
  const location = useLocation();
  const navigation = useNavigation();

  const { data: user, isLoading } = useQuery({
    ...authQuery(),
    initialData: data,
    enabled: !!token,
  });

  React.useEffect(() => {
    if (user) {
      dispatch({ type: 'SET_AUTH_USER', payload: user });
    }
  }, [dispatch, user]);

  if (!token) {
    return <Navigate to={paths.AUTH_URL_PATH} state={{ from: location }} replace />;
  }

  if (isLoading || !user) return <div>Please wait...</div>;

  return (
    <div className="min-h-full flex flex-col">
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onAction={() => showAlert(dispatch)}
        onActionMessage="Close"
      />

      <Navbar logout={() => logout()} />

      <main className="relative flex-auto h-full w-full px-6 tablet:px-10 py-8">
        {navigation.state === 'loading' && (
          <div
            className="fixed bg-black/[0.2] w-full h-full top-0 left-0 flex flex-col items-center 
           justify-center z-40 font-normal"
          >
            <BounceLoader color="#303030" size={60} />
            <span className="text-white bg-black/30 rounded-[5px] px-3 text-[15px] inline-block mt-4">
              Please wait...
            </span>
          </div>
        )}
        <Outlet />
      </main>
    </div>
  );
}

export default Layout;
