import { AuthToken } from '../types/types';
import http from '../utils/http';

export async function login(phoneNumber: string, otp: string) {
  if (phoneNumber && !otp) {
    await http.post('/auth/phone/send-code', {
      phoneNumber,
    });
    return 'OTP-SENT';
  }

  const response = await http.post<AuthToken>('/auth/phone/verify-code', {
    phoneNumber,
    verificationCode: otp,
  });
  return response.data;
}

export async function getAuthUser() {
  const response = await http.get('/user/profile');
  return response.data;
}

export async function getMembers() {
  const response = await http.get('/broker/members');
  return response.data;
}

export async function getCommission() {
  const response = await http.get('/broker/commission');
  return response.data;
}

export async function getCompanies() {
  const response = await http.get('/broker/companies');
  return response.data;
}

export async function getMembersByCompany(companyId: string) {
  const response = await http.get(`/broker/companies/${companyId}/members`);
  return response.data;
}
