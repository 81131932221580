import React from 'react';
import Icon from '../Icon/Icon';

type ModalProps = {
  onClose?: () => void;
  onHide?: () => void;
  show: boolean;
  children: React.ReactNode;
};

function Modal({ children, show, onClose, onHide }: ModalProps) {
  const elementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function listener(event: MouseEvent | TouchEvent) {
      if (elementRef?.current) {
        const element = elementRef.current;
        if (element && element.contains(event.target as Node)) {
          return;
        }
        onHide?.();
      }
    }

    if (show && onHide) {
      document.addEventListener('mousedown', listener);
    }

    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [show, onHide]);

  if (!show) return null;

  return (
    <div
      className="modal fixed z-[60] inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 tablet:p-0">
        <div
          className="fixed inset-0 bg-[#303030] bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <span
          className="hidden tablet:inline-block tablet:align-middle tablet:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div
          ref={elementRef}
          className="mobile:w-full relative flex flex-col overflow-hidden transform transition-all"
        >
          {onClose && (
            <div className="w-full h-9 mb-7">
              <button
                type="button"
                onClick={onClose}
                className="w-9 h-9 bg-white rounded-full flex items-center justify-center float-right cursor-pointer"
              >
                <Icon id="close" className="w-3 h-3" />
              </button>
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}

Modal.defaultProps = {
  onHide: undefined,
  onClose: undefined,
};

export default Modal;
