import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { formatPhoneNumber } from 'react-phone-number-input/input';
import { Link, useLoaderData, useParams } from 'react-router-dom';
import { getMembersByCompany } from '../../api/api';
import Icon from '../../components/Icon/Icon';
import { Member } from '../../types/types';
import Input from '../../components/Inputs/Input/Input';
import { paths } from '../../routes';
import { showAlert, useAppContext } from '../../context/appContext';
import MemberDetails from '../../components/Modal/MemberDetails/MemberDetails';

const companyMembersQuery = (groupId: string) => ({
  queryKey: ['companies', groupId],
  queryFn: () => getMembersByCompany(groupId),
});

export function loader(queryClient: QueryClient) {
  return async ({ params }: { params: Record<string, any> }) => {
    let error = {};
    let data = [] as Member[];

    try {
      const query = companyMembersQuery(params.groupId);
      data = queryClient.getQueryData(query.queryKey) ?? (await queryClient.fetchQuery(query));
    } catch (queryError: any) {
      error = queryError;
    }

    if (Object.keys(error).length) {
      return { error };
    }

    return { data };
  };
}

function Members() {
  const [, dispatch] = useAppContext();
  const [filteredMembers, setFilteredMembers] = React.useState<Member[]>();
  const [memberDetails, setMemberDetails] = React.useState<Member>();
  const { data: members, error } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;
  const { company } = useParams<Record<string, any>>();

  React.useEffect(() => {
    if (error && Object.keys(error).length) {
      const { message } = (error || {}) as any;
      showAlert(dispatch, {
        show: true,
        message,
        type: 'ERROR',
      });
    }
  }, [dispatch, error]);

  const handleSearch = (value: string) => {
    if (!value || !members) {
      setFilteredMembers(undefined);
      return;
    }

    setFilteredMembers(
      members.filter(
        (fndMember: Member) =>
          `${fndMember.firstName} ${fndMember.lastName}`
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          `${fndMember.lastName} ${fndMember.firstName}`
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          fndMember.phoneNumber?.includes(value) ||
          fndMember.email?.includes(value),
      ),
    );
  };

  return (
    <>
      <div>
        <div className="flex flex-col tablet:flex-row justify-between w-full tablet:items-center mb-8">
          <h3 className="text-river-main-gray uppercase  font-medium text-[20px] leading-[36px] tracking-[0.03em]">
            <Link to={paths.GROUPS_URL_PATH}>GROUP</Link>{' '}
            <span className="text-river-black font-semibold">/ {company}</span>
          </h3>
          <div className="mt-4 relative w-full tablet:mt-0 tablet:w-[40%]">
            <div className="relative w-full">
              <Icon
                id="searchIcon"
                className="absolute text-river-main-gray w-[18px] h-[18px] my-auto top-0 bottom-0
                    left-4 !opacity-90"
              />
              <Input
                name="name"
                placeholder="Search here"
                className="!py-[10px] px-3 rounded-md !pl-[45px] bg-white focus:border-none focus:border-river-black
                    focus:ring-river-black focus:ring-[1.3px] placeholder:!text-river-main-gray placeholder:!font-light
                    placeholder:!opacity-50 focus:!ring-opacity-80 placeholder:!text-sm border border-river-main-gray border-opacity-20"
                onChange={event => handleSearch(event.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="bg-white rounded-sm px-5 pt-5 pb-7 tablet:px-10 tablet:pt-10 tablet:pb-14">
          <div className="flex justify-between w-full items-center mb-4">
            <h3 className="font-semibold uppercase text-river-main-gray text-[15px] leading-[28px] tracking-[0.03em]">
              MEMBERS
            </h3>
          </div>
          <div className="w-full">
            <table className="min-w-full divide-y divide-gray-200 border-b">
              <thead>
                <tr>
                  {['Firstname', 'Lastname', 'Email', 'Phone number', 'Subscribed'].map(head => (
                    <th
                      key={head}
                      scope="col"
                      className={`first:!pl-0 px-6 py-3 text-left text-xs font-medium uppercase !tracking-wider ${
                        ['#', 'Phone number', 'Subscribed'].includes(head)
                          ? 'mobile:table-cell'
                          : 'mobile:hidden'
                      }`}
                    >
                      {head}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="divide-y">
                {(filteredMembers || members || []).map((fndMember: Member, index: number) => (
                  <tr
                    key={index}
                    className="text-left text-[15px] leading-[24px] tracking-[0.03em] text-river-deep-gray"
                  >
                    <td className="py-4 whitespace-nowrap tablet:table-cell hidden">
                      {fndMember.firstName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap tablet:table-cell hidden">
                      {fndMember.lastName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap tablet:table-cell hidden">
                      {fndMember.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button type="button" onClick={() => setMemberDetails(fndMember)}>
                        {formatPhoneNumber(fndMember.phoneNumber)}
                      </button>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`rounded-full px-3 py-1 text-[14px] tracking-normal font-normal ${
                          fndMember.isSubscribed
                            ? 'text-green-500 bg-[#E8F8EA]'
                            : 'text-red-500 bg-[#FFE5E0]'
                        }`}
                      >
                        {fndMember.isSubscribed ? 'Yes' : 'No'}
                      </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {memberDetails && (
        <MemberDetails onCloseModal={() => setMemberDetails(undefined)} member={memberDetails} />
      )}
    </>
  );
}

export default Members;
