import * as React from 'react';
import useDropdownTrigger from '../../hooks/useDropdownTrigger';

type OptionsContextType = {
  isExpanded?: boolean;
  trigger?: () => void;
  getTriggerProps?: (props: Record<string, any>) => any;
};

const OptionsMenuContext = React.createContext<OptionsContextType | undefined>(undefined);
OptionsMenuContext.displayName = 'OptionsMenuContext';

const useOptions = () => {
  const context = React.useContext(OptionsMenuContext);
  if (context === undefined) {
    throw new Error(`\`useOptions\` must be used within a <OptionsMenu/>`);
  }

  return context;
};

function OptionsMenu({ children, className = '' }: OptionsProps) {
  const optionsMenuRef = React.useRef<HTMLDivElement>(null);
  const { isExpanded, trigger, getTriggerProps } = useDropdownTrigger(optionsMenuRef);

  const value = React.useMemo(
    () => ({
      isExpanded,
      trigger,
      getTriggerProps,
    }),
    [isExpanded, trigger, getTriggerProps],
  );

  return (
    <OptionsMenuContext.Provider value={value}>
      <div ref={optionsMenuRef} className={`w-full ${className}`}>
        {children}
      </div>
    </OptionsMenuContext.Provider>
  );
}

function OptionButton({ children, className = '' }: OptionButtonProps) {
  const { trigger } = useOptions();
  return (
    <button
      type="button"
      onClick={trigger}
      className={className}
      id="user-menu-button"
      aria-expanded="false"
      aria-haspopup="true"
    >
      {children}
    </button>
  );
}

function OptionList({ children, className = '' }: OptionListProps) {
  const { isExpanded } = useOptions();

  return (
    <div
      className={`px-3 absolute transition-all ease-in-out overflow-hidden flex flex-col w-max tablet:w-full ${className}
      ${!isExpanded ? 'h-0 invisible border-none' : ''} ${
        Array.isArray(children) && children.length > 1 ? 'divide-y divide-[#e9ecef]' : ''
      }`}
      role="menu"
    >
      {children}
    </div>
  );
}

function OptionItem({
  as: Component = 'div',
  children,
  onClick,
  className = '',
  ...rest
}: OptionItemProps) {
  const { getTriggerProps } = useOptions();
  return (
    <Component
      {...rest}
      {...(getTriggerProps &&
        getTriggerProps({
          onClick,
        }))}
      className={`block pt-[10px] pb-[10px] hover:text-river-blue group cursor-pointer ${className}`}
      role="menuitem"
    >
      {children}
    </Component>
  );
}

type OptionsProps = {
  className?: string;
  children: React.ReactNode;
};

type OptionButtonProps = {
  className?: string;
  children: React.ReactNode;
};

type OptionListProps = {
  className?: string;
  children: React.ReactNode;
};

type OptionItemProps = {
  as?: any;
  children: React.ReactNode;
  className?: string;
  onClick?: () => void;
  [x: string]: any;
};

export { OptionsMenu, OptionButton, OptionList, OptionItem };
