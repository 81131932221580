import * as React from 'react';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';
import ErrorGraphicIllustration from '../../assets/images/image_error_graphic_illustration.png';
import { env } from '../../utils/utils';
import { reportErrorToSentry } from '../../utils/reportToSentry';
import MarkdownHTML from '../MarkdownHTML/MarkdownHTML';

export function ErrorBoundary() {
  const error = useRouteError() as any;

  React.useEffect(() => {
    if (error.status !== 404 && !isRouteErrorResponse(error)) {
      reportErrorToSentry(error);
    }
  }, [error]);

  if (error.status === 404 && isRouteErrorResponse(error)) {
    return (
      <div className="min-h-full flex flex-col justify-center">
        <div role="alert" className="flex flex-col items-center justify-center">
          <h3 className="text-[#FF9B5B] text-lg font-medium mb-2">Hmm. Are we lost? 🤔</h3>
          <span>
            <pre className="inline-flex">
              {`Looks like you're lost. Don't worry let's take you home.`}
            </pre>
          </span>

          <img
            src={ErrorGraphicIllustration}
            alt="error graphic"
            className="w-[250px] tablet:w-[350px]"
          />

          <a
            href="/"
            className="py-[15px] px-[20px] text-sm rounded-md bg-river-blue justify-center 
            text-white inline-flex items-center hover:bg-river-light-blue cursor-pointer 
            transition-all ease-linear w-[250px]  mt-10"
          >
            Take me Home
          </a>

          <MarkdownHTML
            className="text-river-black text-sm mt-6 text-center"
            content={`If this persists, please reach out to our [support team.](${env(
              'RIVER_SUPPORT_URL',
            )})`}
          />
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-full flex flex-col justify-center">
      <div role="alert" className="flex flex-col items-center justify-center">
        <h3 className="text-[#FF9B5B] text-lg font-medium mb-2">Sorry !!!</h3>
        <span>
          <pre className="inline-flex">
            {error.message || 'Something unusual happened. Kindly retry your last action.'}
          </pre>
        </span>

        <img
          src={ErrorGraphicIllustration}
          alt="error graphic"
          className="w-[250px] tablet:w-[350px]"
        />

        <a
          href="/"
          className="py-[15px] px-[20px] text-sm rounded-md bg-river-blue justify-center 
            text-white inline-flex items-center hover:bg-river-light-blue cursor-pointer 
            transition-all ease-linear w-[250px]  mt-10"
        >
          Try again!
        </a>

        <MarkdownHTML
          className="text-river-black text-sm mt-6 text-center"
          content={`If this persists, please reach out to our [support team.](${env(
            'RIVER_SUPPORT_URL',
          )})`}
        />
      </div>
    </div>
  );
}

export default ErrorBoundary;
