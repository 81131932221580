import React from 'react';
import { Navigate, redirect, useActionData, useNavigation, useSubmit } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import moment from 'moment';
import { QueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import LoginBanner from '../../assets/images/image_login_banner.svg';
import { ReactComponent as RiverLogo } from '../../assets/icons/icon_river_logo.svg';
import Button from '../../components/Button/Button';
import PhoneInput from '../../components/Inputs/PhoneInput/PhoneInput';
import PinInput from '../../components/Inputs/PinInput/PinInput';
import { AUTH_TOKEN_STORE_KEY, getCookie, isRole, setCookie } from '../../utils/utils';
import useValidator from '../../hooks/useValidator';
import { paths } from '../../routes';
import { getAuthUser, login } from '../../api/api';
import { showAlert, subscribeToExternals, useAppContext } from '../../context/appContext';
import Alert from '../../components/Alert/Alert';
import { Role } from '../../types/types';

const customValidators = {
  phone: {
    message: 'The phone number must be a valid US number.',
    rule: (val: string) => {
      return isValidPhoneNumber(val);
    },
    required: false,
  },
};

export const authQuery = () => ({
  queryKey: ['auth'],
  queryFn: () => getAuthUser(),
});

export function action(queryClient: QueryClient) {
  return async ({ request }: { request: Request }) => {
    const values = Object.fromEntries(await request.formData()) as {
      phoneNumber: string;
      verificationCode: string;
    };

    let errors = {};

    try {
      const response = await login(values.phoneNumber, values.verificationCode);

      if (response === 'OTP-SENT') return { hasSentOTP: true };

      await setCookie(
        AUTH_TOKEN_STORE_KEY,
        response.token,
        Math.ceil(moment.duration(moment(response.expiresAt).diff(moment())).asDays()),
      );

      let user = await getAuthUser();

      user = {
        ...user,
        roles: user?.roles
          ? (user?.roles?.map((fndRole: any) => fndRole.name) satisfies Role[])
          : [],
      };

      if (!isRole(user.roles, 'BROKER')) {
        throw new Error(
          `Oops. Looks like you're not authorized to access this application. Please contact the support team!`,
        );
      }

      subscribeToExternals(await queryClient.fetchQuery(authQuery()));
    } catch (error: any) {
      Cookies.remove(AUTH_TOKEN_STORE_KEY); // remove cookie when any error
      errors = { message: error?.message };
    }

    if (Object.keys(errors).length) {
      return { errors };
    }

    return redirect(paths.DASHBOARD_URL_PATH);
  };
}

function Login() {
  const [{ alert }, dispatch] = useAppContext();
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');
  const [validator, showValidationMessage] = useValidator({}, customValidators);
  const navigation = useNavigation();
  const submit = useSubmit();

  const { hasSentOTP, errors }: any = useActionData() || {};

  React.useEffect(() => {
    if (errors && Object.keys(errors).length) {
      setVerificationCode('');
      showAlert(dispatch, {
        show: true,
        message: errors.message,
        type: 'ERROR',
      });
    }
  }, [dispatch, errors]);

  if (getCookie(AUTH_TOKEN_STORE_KEY)) {
    return <Navigate to={paths.DASHBOARD_URL_PATH} replace />;
  }

  const handleSendOTP = () => {
    if (!validator.allValid()) {
      showValidationMessage(true);
      return;
    }

    const formData = new FormData();
    formData.append('phoneNumber', phoneNumber);
    formData.append('verificationCode', verificationCode);

    submit(formData, { method: 'post' });
  };

  return (
    <div className="w-full min-h-full flex flex-col bg-white overflow-y-scroll">
      <Alert
        show={alert.show}
        type={alert.type}
        message={alert.message}
        onAction={() => showAlert(dispatch)}
        onActionMessage="Close"
      />

      <div className="w-full flex-none">
        <img
          src={LoginBanner}
          alt="login banner header"
          className="w-full h-full object-cover object-center"
        />
      </div>
      <div className="relative flex-auto h-full bg-white w-full">
        <div
          className="overflow-y-scroll rounded-md shadow-xl z-10 bg-white max-w-md mx-auto flex 
          flex-col px-14 py-12 text-center absolute left-0 right-0 -top-24"
        >
          <div className="mb-10">
            <RiverLogo className="w-20 inline" />
            <span className="block text-xs text-river-main-gray mt-2">Broker Portal</span>
          </div>

          <div>
            <h2 className="text-[20px] leading-[36px] tracking-[0.03em] font-semibold mb-20">
              Welcome Back
            </h2>

            {!hasSentOTP ? (
              <>
                <div className="mb-6">
                  <PhoneInput
                    name="phoneNumber"
                    placeholder="(223) 498-3974"
                    value={phoneNumber}
                    onChange={value => setPhoneNumber(value)}
                    className="peer-[.validator-error]/phoneNumber:!border-river-red 
                    peer-[.validator-error]/phoneNumber:!mb-5"
                    validator={validator.message('phoneNumber', phoneNumber, 'required|phone', {
                      messages: {
                        required: 'Phone number is required.',
                      },
                      className: 'peer/phoneNumber validator-error',
                    })}
                  />
                </div>

                <p className="mb-14 text-xs">
                  Enter your phone number to receive a verification code to sign-in.
                </p>

                <Button
                  type="submit"
                  label="Login"
                  className="w-[250px]"
                  onClick={handleSendOTP}
                  disabled={navigation.state === 'submitting'}
                  loading={navigation.state === 'submitting'}
                />
              </>
            ) : (
              <>
                <div className="mb-6">
                  <PinInput
                    name="pin"
                    length={4}
                    initialValue={verificationCode}
                    focus
                    secret={false}
                    type="numeric"
                    onChange={value => setVerificationCode(value)}
                    className="peer-[.validator-error]/pin:!border-river-red 
                    peer-[.validator-error]/pin:!mb-5"
                    validator={validator.message(
                      'verificationCode',
                      verificationCode,
                      'required|numeric|min:4',
                      {
                        messages: {
                          required: 'Enter the 4 digit code.',
                          min: 'Enter the correct 4 digit code sent.',
                        },
                        className: 'peer/pin validator-error',
                      },
                    )}
                  />
                </div>

                <p className="mb-14 text-xs">
                  A 4 digit code was sent to{' '}
                  <span className="inline-flex">
                    {`***-***-${phoneNumber ? phoneNumber.slice(-4) : 'your number'}`}
                  </span>
                  , enter the code in the box above
                </p>

                <Button
                  type="submit"
                  label="Verify"
                  className="w-[250px]"
                  onClick={handleSendOTP}
                  disabled={navigation.state === 'submitting'}
                  loading={navigation.state === 'submitting'}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
