import React, { ChangeEvent } from 'react';

type InputType = 'text' | 'email' | 'url' | 'password' | 'number' | 'search' | 'tel';

export type InputProps = {
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  type?: InputType;
  error?: boolean;
  [x: string]: any;
};

function Input({ className, type, name, error, onChange, ...props }: InputProps) {
  return (
    <input
      {...props}
      className={`${className} ${
        error ? '!border-river-red' : ''
      } disabled:border-[#dfe5eb] disabled:bg-[#f5f7fa]`}
      type={type}
      name={name}
      aria-label={name}
      onChange={onChange}
    />
  );
}

Input.defaultProps = {
  className: '',
  type: 'text',
  error: false,
};

export default Input;
