import { QueryClient, useQuery } from '@tanstack/react-query';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { formatPhoneNumber } from 'react-phone-number-input';
import { useLoaderData } from 'react-router-dom';
import { getCommission, getMembers } from '../../api/api';
import Icon from '../../components/Icon/Icon';
import Input from '../../components/Inputs/Input/Input';
import { showAlert, useAppContext } from '../../context/appContext';
import { Commission, Member } from '../../types/types';
import { env } from '../../utils/utils';
import MemberDetails from '../../components/Modal/MemberDetails/MemberDetails';

const membersQuery = () => ({
  queryKey: ['members'],
  queryFn: () => getMembers(),
});

const commissionQuery = () => ({
  queryKey: ['commission'],
  queryFn: () => getCommission(),
});

export function loader(queryClient: QueryClient) {
  return async () => {
    let error = {};
    let data = {
      members: [],
      commission: {
        total: 0,
        thisMonth: 0,
        totalPaid: 0,
        outstandingPayment: 0,
      },
    } satisfies { members: Member[]; commission: Commission };

    try {
      const commission =
        queryClient.getQueryData(commissionQuery().queryKey) ??
        (await queryClient.fetchQuery(commissionQuery()));

      if (!queryClient.getQueryData(membersQuery().queryKey)) {
        await queryClient.fetchQuery(membersQuery());
      }

      data = {
        ...data,
        commission,
      };
    } catch (queryError: any) {
      error = queryError;
    }

    return { data, error };
  };
}

function Dashboard() {
  const [{ user }, dispatch] = useAppContext();
  const [hasCopiedURL, setHasCopiedURL] = React.useState(false);
  const [filteredMembers, setFilteredMembers] = React.useState(undefined);
  const [memberDetails, setMemberDetails] = React.useState<Member>();

  const { data, error } = useLoaderData() as Awaited<ReturnType<ReturnType<typeof loader>>>;

  const { commission } = data;

  const { data: members } = useQuery({ ...membersQuery(), initialData: data.members });

  const brokerURL = `${env('RIVER_APP_URL')}/join/broker${
    user?.assignedBrokerCode ? `/${user?.assignedBrokerCode.code}` : ''
  }`;

  React.useEffect(() => {
    if (error && Object.keys(error).length) {
      const { message } = (error || {}) as any;
      showAlert(dispatch, {
        show: true,
        message,
        type: 'ERROR',
      });
    }
  }, [dispatch, error]);

  const handleClipboard = () => {
    setHasCopiedURL(true);
    showAlert(
      dispatch,
      {
        show: true,
        message: 'URL successfully copied to your clipboard.',
        type: 'INFO',
      },
      4000,
    );
    setTimeout(() => {
      setHasCopiedURL(false);
    }, 4000);
  };

  const handleSearch = (value: string) => {
    if (!value || !members) {
      setFilteredMembers(undefined);
      return;
    }

    setFilteredMembers(
      members.filter(
        (fndMember: Member) =>
          `${fndMember.firstName} ${fndMember.lastName}`
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          `${fndMember.lastName} ${fndMember.firstName}`
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          fndMember.phoneNumber?.includes(value) ||
          fndMember.email?.includes(value),
      ),
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="mb-4">
          <h2 className="uppercase font-semibold text-[20px] leading-[36px] tracking-[0.03em]">
            WELCOME {user?.firstName}
          </h2>
        </div>
        <div className="space-y-7">
          <div className="bg-white rounded-xl p-5">
            <h3 className="text-river-main-gray font-normal">Your URL</h3>
            <div className="flex flex-col tablet:flex-row space-y-3 tablet:space-y-0 tablet:space-x-3 tablet:justify-between mt-2">
              <div className="bg-[#F7F8F8] w-full p-[10px] break-words font-normal text-[15px] rounded-md">
                {brokerURL}
              </div>
              <CopyToClipboard onCopy={handleClipboard} text={brokerURL}>
                <button
                  type="button"
                  className="bg-[#8B969D] rounded-md tablet:shrink-0 w-full tablet:w-[100px] flex flex-col justify-center
                  items-center hover:opacity-80 h-[70px] tablet:h-auto"
                >
                  {!hasCopiedURL ? (
                    <Icon id="clipboard" className="w-6 tablet:w-4" />
                  ) : (
                    <Icon id="checked" className="w-[40px] tablet:w-[30px]" />
                  )}
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="bg-white rounded-sm px-10 pt-6 pb-10 mb-4">
            <h3 className="text-river-main-gray font-medium uppercase">YOUR STATS</h3>
            <div className="flex flex-col tablet:flex-row space-y-6 tablet:space-y-0 tablet:space-x-14 mt-3">
              <div className="w-full bg-[#EEE6F9] p-8 rounded-md space-y-4 border border-river-main-gray border-opacity-10">
                <div className="flex justify-between items-center">
                  <div className="text-2xl font-semibold center text-[#9C6ADE]">
                    {(members || []).length}
                  </div>
                  <Icon id="statsEligibleMembers" className="w-8" />
                </div>
                <p className="text-[15px] font-normal">Eligible Members</p>
              </div>
              <div className="w-full bg-[#FDEFE2] p-8 rounded-md space-y-4 border border-river-main-gray border-opacity-10">
                <div className="flex justify-between items-center">
                  <div className="text-2xl font-semibold center text-[#F39342]">
                    {(members || []).filter((fndMember: Member) => fndMember.isSubscribed).length}
                  </div>
                  <Icon id="statsEnrolledMembers" className="w-8" />
                </div>
                <p className="text-[15px] font-normal">Enrolled Members</p>
              </div>
              <div className="w-full bg-[#E9F9E6] p-8 rounded-md space-y-4 border border-river-main-gray border-opacity-10">
                <div className="flex justify-between items-center">
                  <div className="text-2xl font-semibold center text-[#43C42F]">
                    {new Intl.NumberFormat('es-US', { currency: 'USD', style: 'currency' }).format(
                      +(commission.totalPaid / 100).toFixed(2),
                    )}
                  </div>
                  <Icon id="statsTotalPaid" className="w-8" />
                </div>
                <p className="text-[15px] font-normal">Total Paid</p>
              </div>
            </div>
          </div>
          <div className="bg-white rounded-sm px-5 pt-5 pb-7 tablet:px-10 tablet:pt-10 tablet:pb-14">
            <div className="flex flex-col tablet:flex-row justify-between w-full tablet:items-center mb-4">
              <h3 className="text-river-main-gray font-medium uppercase">MEMBERS</h3>
              <div className="mt-4 relative w-full tablet:mt-0 tablet:w-[40%]">
                <Icon
                  id="searchIcon"
                  className="absolute text-river-main-gray w-[18px] h-[18px] my-auto top-0 bottom-0
                  left-4 !opacity-80"
                />
                <Input
                  name="name"
                  placeholder="Search here"
                  className="!py-[10px] px-3 rounded-md !pl-[45px] bg-[#F7F7F7] border-none focus:border-river-black
                  focus:ring-river-black focus:ring-[1.3px] placeholder:!text-river-main-gray placeholder:!font-light
                  placeholder:!opacity-30 focus:!ring-opacity-80 placeholder:!text-sm"
                  onChange={event => handleSearch(event.target.value)}
                />
              </div>
            </div>
            <div className="w-full">
              <table className="min-w-full divide-y divide-gray-200 border-b">
                <thead>
                  <tr>
                    {['#', 'Firstname', 'Lastname', 'Email', 'Phone number', 'Subscribed'].map(
                      head => (
                        <th
                          key={head}
                          scope="col"
                          className={`px-3 tablet:px-6 py-3 text-left text-xs font-medium uppercase !tracking-wider ${
                            ['#', 'Phone number', 'Subscribed'].includes(head)
                              ? 'mobile:table-cell'
                              : 'mobile:hidden'
                          }`}
                        >
                          {head}
                        </th>
                      ),
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y">
                  {(filteredMembers || members || []).map((fndMember: Member, index: number) => (
                    <tr
                      key={fndMember.phoneNumber}
                      className="text-left text-[15px] leading-[24px] tracking-[0.03em] text-river-deep-gray"
                    >
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap">
                        {index + 1}
                      </td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap tablet:table-cell hidden">
                        {fndMember.firstName}
                      </td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap tablet:table-cell hidden">
                        {fndMember.lastName}
                      </td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap tablet:table-cell hidden">
                        {fndMember.email}
                      </td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap">
                        <button type="button" onClick={() => setMemberDetails(fndMember)}>
                          {formatPhoneNumber(fndMember.phoneNumber)}
                        </button>
                      </td>
                      <td className="px-3 py-3 tablet:px-6 tablet:py-4 whitespace-nowrap mobile:text-center mobile:place-content-center">
                        <span
                          className={`rounded-full px-3 py-1 text-[14px] tracking-normal font-normal ${
                            fndMember.isSubscribed
                              ? 'text-green-500 bg-[#E8F8EA]'
                              : 'text-red-500 bg-[#FFE5E0]'
                          }`}
                        >
                          {fndMember.isSubscribed ? 'Yes' : 'No'}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {memberDetails && (
        <MemberDetails onCloseModal={() => setMemberDetails(undefined)} member={memberDetails} />
      )}
    </>
  );
}

export default Dashboard;
