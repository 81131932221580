import React from 'react';
import { QueryClient } from '@tanstack/react-query';
import { Link, useLoaderData } from 'react-router-dom';
import { getCompanies } from '../../api/api';
import Icon from '../../components/Icon/Icon';
import { paths } from '../../routes';
import { Company } from '../../types/types';
import Input from '../../components/Inputs/Input/Input';
import { showAlert, useAppContext } from '../../context/appContext';

const companiesQuery = () => ({
  queryKey: ['companies'],
  queryFn: () => getCompanies(),
});

export function loader(queryClient: QueryClient) {
  return async () => {
    let error = {};
    let data = [] as Company[];

    try {
      data =
        queryClient.getQueryData(companiesQuery().queryKey) ??
        (await queryClient.fetchQuery(companiesQuery()));
    } catch (queryError: any) {
      error = queryError;
    }

    if (Object.keys(error).length) {
      return { error };
    }

    return { data };
  };
}

function Groups() {
  const [, dispatch] = useAppContext();
  const [filteredCompanies, setFilteredCompanies] = React.useState<Company[]>();
  const { data: companies, error } = useLoaderData() as Awaited<
    ReturnType<ReturnType<typeof loader>>
  >;

  React.useEffect(() => {
    if (error && Object.keys(error).length) {
      const { message } = (error || {}) as any;
      showAlert(dispatch, {
        show: true,
        message,
        type: 'ERROR',
      });
    }
  }, [dispatch, error]);

  const handleSearch = (value: string) => {
    if (!value || !companies) {
      setFilteredCompanies(undefined);
      return;
    }

    setFilteredCompanies(
      companies.filter((fndCompany: Company) =>
        fndCompany.name.toLowerCase().includes(value.toLowerCase()),
      ),
    );
  };

  return (
    <div>
      <div className="flex flex-col tablet:flex-row justify-between w-full tablet:items-center mb-8">
        <h3 className="uppercase font-semibold text-[20px] leading-[36px] tracking-[0.03em]">
          GROUP
        </h3>
        <div className="mt-4 relative w-full tablet:mt-0 tablet:w-[40%]">
          <div className="relative w-full">
            <Icon
              id="searchIcon"
              className="absolute text-river-main-gray w-[18px] h-[18px] my-auto top-0 bottom-0
                  left-4 !opacity-90"
            />
            <Input
              name="name"
              placeholder="Search here"
              className="!py-[10px] px-3 rounded-md !pl-[45px] bg-white focus:border-none focus:border-river-black
                  focus:ring-river-black focus:ring-[1.3px] placeholder:!text-river-main-gray placeholder:!font-light
                  placeholder:!opacity-50 focus:!ring-opacity-80 placeholder:!text-sm border border-river-main-gray border-opacity-20"
              onChange={event => handleSearch(event.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="bg-white rounded-sm px-5 pt-5 pb-7 tablet:px-10 tablet:pt-10 tablet:pb-14 space-y-12">
        <div className="w-full">
          <table className="min-w-full divide-y divide-gray-200 border-b">
            <thead>
              <tr>
                {['#', 'Group name', 'Employee number', 'Actions'].map(head => (
                  <th
                    key={head}
                    scope="col"
                    className="px-3 py-3 tablet:px-6 tablet:py-3 text-left text-xs font-medium uppercase !tracking-wider"
                  >
                    {head}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y">
              {(filteredCompanies || companies || []).map((fndCompany: Company, index: number) => (
                <tr
                  key={fndCompany.id}
                  className="text-left text-[15px] leading-[24px] tracking-[0.03em] text-river-deep-gray"
                >
                  <td className="px-3 py-3 tablet:px-6 tablet:py-4">{index + 1}</td>
                  <td className="px-3 py-3 tablet:px-6 tablet:py-4">
                    <Link
                      to={paths.GROUP_MEMBERS_URL_PATH.replace(':groupId', fndCompany.id).replace(
                        ':company',
                        fndCompany.name,
                      )}
                      className="hover:opacity-80"
                    >
                      {fndCompany.name}
                    </Link>
                  </td>
                  <td className="px-3 py-3 tablet:px-6 tablet:py-4 mobile:text-center mobile:place-content-center">
                    {fndCompany.memberCount}
                  </td>
                  <td className="px-3 py-3 tablet:px-6 tablet:py-4 text-center w-[10%]">
                    <Link
                      to={paths.GROUP_MEMBERS_URL_PATH.replace(':groupId', fndCompany.id).replace(
                        ':company',
                        fndCompany.name,
                      )}
                      className="hover:opacity-80 rounded-full bg-[#E2F4FD] w-10 h-10 flex items-center justify-center mx-auto"
                    >
                      <Icon id="moreAction" className="w-5" />
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Groups;
