import React from 'react';
import ReactPinInput from 'react-pin-input';

type PinInputProps = {
  className?: string;
  type: 'numeric' | 'custom';
  initialValue: string | number;
  length: number;
  name: string;
  error?: boolean;
  focus?: boolean;
  secret?: boolean;
  onChange: (value: string) => void;
  onComplete?: (value: string, index: number) => void;
  validator?: any;
};

function PinInput({
  className,
  name,
  type,
  initialValue,
  length,
  focus,
  secret,
  onChange,
  onComplete,
  error,
  validator,
}: PinInputProps) {
  return (
    <div className="relative">
      {validator}

      <div className={`${className} pin-input ${error ? 'error' : ''}`}>
        <ReactPinInput
          ariaLabel={name}
          type={type}
          initialValue={initialValue}
          length={length || 4}
          focus={focus}
          secret={secret}
          onChange={onChange}
          onComplete={onComplete}
          inputMode={type}
          style={undefined}
          inputStyle={undefined}
          inputFocusStyle={undefined}
        />
      </div>
    </div>
  );
}

PinInput.defaultProps = {
  focus: true,
  secret: true,
  onComplete: undefined,
};

export default PinInput;
