/* eslint-disable react/no-danger */
import * as React from 'react';
import * as DOMPurify from 'dompurify';
import { markdown } from '../../utils/utils';

type MarkdownHTMLProps = {
  className?: string;
  content: string;
};

function MarkdownHTML({ className, content }: MarkdownHTMLProps) {
  return (
    <p
      className={`markdown ${className}`}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(markdown(content)),
      }}
    />
  );
}

MarkdownHTML.defaultProps = {
  className: '',
};
export default MarkdownHTML;
