import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { AppProvider } from './context/appContext';
import routes from './routes';
import { env } from './utils/utils';

Sentry.init({
  dsn: env('RIVER_SENTRY_DSN'),
  integrations: [new BrowserTracing()],
  environment: env('RIVER_ENV'),
  tracesSampleRate: env('RIVER_ENV') === 'production' ? 0.5 : 1.0,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 10,
    },
  },
});

const router = createBrowserRouter(routes(queryClient));

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <RouterProvider router={router} />
      </AppProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
