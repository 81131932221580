import Cookies from 'js-cookie';
import * as React from 'react';
import { Alert, AppAction, AppDispatch, AppState, AuthUser } from '../types/types';
import { clearCurrentUserOnSentry, reportUserIdentityToSentry } from '../utils/reportToSentry';

const initialState: AppState = {
  user: null,
  alert: { message: '', type: 'INFO', show: false },
};

const AppContext = React.createContext<[state: AppState, dispatch: AppDispatch] | undefined>(
  undefined,
);

AppContext.displayName = 'AppContext';

function appReducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case 'SET_AUTH_USER':
      return {
        ...state,
        user: action.payload,
      };
    case 'SHOW_ALERT':
      return {
        ...state,
        alert: action.payload,
      };
    default:
      return state;
  }
}

function AppProvider({ children }: { children: React.ReactNode }) {
  const [isHardLoading, setHardLoading] = React.useState(true);
  const [state, dispatch] = React.useReducer(appReducer, initialState);

  const value = React.useMemo(() => [state, dispatch], [state, dispatch]) as [
    state: AppState,
    dispatch: AppDispatch,
  ];

  React.useEffect(() => {
    (async function fakeHardLoadRequest() {
      // eslint-disable-next-line no-promise-executor-return
      return new Promise(resolve => setTimeout(() => resolve(null), 2000));
    })().then(() => {
      const el = document.querySelector('.pre-loader');
      if (el) {
        el.remove();
        setHardLoading(fndIsHardLoading => !fndIsHardLoading);
      }
    });
  }, []);

  if (isHardLoading) {
    return null;
  }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
}

function useAppContext() {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error(`\`useAppContext\` must be used within a AppProvider`);
  }
  return context;
}

export function showAlert(
  dispatch: AppDispatch,
  { show, type, message } = {} as Alert,
  autoDismissTimer = 0,
) {
  dispatch({
    type: 'SHOW_ALERT',
    payload: { show, type, message },
  });

  if (autoDismissTimer) {
    setTimeout(
      () =>
        dispatch({
          type: 'SHOW_ALERT',
          payload: { show: false, type: 'INFO', message: '' },
        }),
      autoDismissTimer,
    );
  }
}

export function logout() {
  Cookies.remove('_river_broker_tokid');
  clearCurrentUserOnSentry();
  window.location.reload();
}

export function subscribeToExternals(authUser: AuthUser) {
  reportUserIdentityToSentry({
    phoneNumber: authUser.phoneNumber,
    id: authUser.id,
  });
}

export { AppProvider, useAppContext };
