import React from 'react';
import SimpleReactValidator from 'simple-react-validator';

type ValidatorRule = {
  message: string;
  rule: (val: any, params?: any) => boolean;
};

const useValidator = (
  customMessage: Record<string, unknown> = {},
  customValidator: Record<string, ValidatorRule> = {},
) => {
  const [show, setShow] = React.useState(false);

  const validator = React.useRef(
    new SimpleReactValidator({
      messages: customMessage,
      validators: customValidator,
    }),
  );

  if (show) {
    validator.current.showMessages();
  }

  return [validator.current, setShow] as any;
};

export default useValidator;
