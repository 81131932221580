import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import Modal from '../Modal';
import Button from '../../Button/Button';
import Icon from '../../Icon/Icon';

type ModalProps = {
  onCloseModal: () => void;
  brokerURL: string;
};

function IndividualEnrollPrompt({ onCloseModal, brokerURL }: ModalProps) {
  const [hasCopiedURL, setHasCopiedURL] = React.useState(false);

  const handleClipboard = () => {
    setHasCopiedURL(true);
    setTimeout(() => {
      setHasCopiedURL(false);
    }, 4000);
  };

  return (
    <Modal onClose={onCloseModal} show>
      <div className="rounded-xl bg-white w-full tablet:w-[600px] laptop:w-[650px] tablet:min-h-[400px]">
        <div className="flex flex-col px-6 tablet:px-12 py-10 items-center">
          <h3 className="font-semibold text-black text-[22px] leading-[28px] tracking-[0.03em] mb-3 text-center">
            Enroll Individual
          </h3>

          <div className="place-items-center text-center mb-8 w-full">
            <div>
              To enroll an individual in a River Health plan, share your special broker link with
              them. You can also use this link below to enroll them yourself.
            </div>

            <hr className="w-[70%] mx-auto my-5" />

            <div className="font-medium text-river-black">
              Copy and paste your broker link into another tab on your browser, select a plan, and
              begin the enrollment process.
              <div
                className="flex flex-row space-x-4 bg-white border border-river-blue pl-3 pr-2 py-[5px] 
                font-normal rounded-md justify-between items-center mt-3 w-full tablet:w-[90%] tablet:mx-auto"
              >
                <p className="text-[15px] text-left overflow-auto">{brokerURL}</p>
                <CopyToClipboard onCopy={handleClipboard} text={brokerURL}>
                  <button
                    type="button"
                    className={`hidden bg-black rounded-md shrink-0 tablet:flex flex-col justify-center items-center hover:opacity-80
                    ${!hasCopiedURL ? 'p-[10px] ' : 'p-[4px]'}`}
                  >
                    {!hasCopiedURL ? (
                      <Icon id="clipboard" className="w-4 h-4" />
                    ) : (
                      <Icon id="checked" className="w-7 h-7" />
                    )}
                  </button>
                </CopyToClipboard>
              </div>
            </div>

            <hr className="w-[70%] mx-auto mt-8 mb-5" />

            <div>
              You will need the individual&apos;s phone number, name, birth date, address, gender,
              phone number, and payment details ready.
            </div>
          </div>

          <Button type="submit" label="Got it!" className="w-[250px]" onClick={onCloseModal} />
        </div>
      </div>
    </Modal>
  );
}

export default IndividualEnrollPrompt;
