/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Icons from '../../assets/icons';

import { ReactComponent as IconLogout } from '../../assets/icons/icon_logout.svg';
import { ReactComponent as IconInputChecked } from '../../assets/icons/icon_input_checked.svg';
import { ReactComponent as IconArrowDown } from '../../assets/icons/icon_select_arrow_down.svg';
import { ReactComponent as IconClipboard } from '../../assets/icons/icon_clipboard.svg';
import { ReactComponent as IconStatsEligibleMembers } from '../../assets/icons/icon_stats_eligible_members.svg';
import { ReactComponent as IconStatsEnrolledMembers } from '../../assets/icons/icon_stats_enrolled_members.svg';
import { ReactComponent as IconStatsOutsPayment } from '../../assets/icons/icon_stats_outs_payment.svg';
import { ReactComponent as IconStatsTotalPaid } from '../../assets/icons/icon_stats_total_paid.svg';
import { ReactComponent as IconSearch } from '../../assets/icons/icon_search.svg';
import { ReactComponent as IconFlagUSA } from '../../assets/icons/icon_flag_usa.svg';
import { ReactComponent as IconFlagSpain } from '../../assets/icons/icon_flag_spain.svg';
import { ReactComponent as IconSuccessChecked } from '../../assets/icons/icon_success_checked.svg';
import { ReactComponent as IconCancelChecked } from '../../assets/icons/icon_cancel_checked.svg';
import { ReactComponent as IconHamburger } from '../../assets/icons/icon_hamburger.svg';
import { ReactComponent as IconFileDownload } from '../../assets/icons/icon_file_download.svg';
import { ReactComponent as IconMoreAction } from '../../assets/icons/icon_more_action.svg';
import { ReactComponent as IconOpenLink } from '../../assets/icons/icon_open_link.svg';

type IconProps = {
  id: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const allIcons: Record<string, (props: React.SVGProps<SVGSVGElement>) => JSX.Element | any> = {
  ...Icons,
  logoutIcon: IconLogout,
  checked: IconInputChecked,
  clipboard: IconClipboard,
  arrowDown: IconArrowDown,
  statsEligibleMembers: IconStatsEligibleMembers,
  statsEnrolledMembers: IconStatsEnrolledMembers,
  statsOutsPayment: IconStatsOutsPayment,
  statsTotalPaid: IconStatsTotalPaid,
  searchIcon: IconSearch,
  flagEN: IconFlagUSA,
  flagES: IconFlagSpain,
  successChecked: IconSuccessChecked,
  cancelChecked: IconCancelChecked,
  hamburger: IconHamburger,
  fileDownload: IconFileDownload,
  moreAction: IconMoreAction,
  openLink: IconOpenLink,
};

function Icon({ id, ...rest }: IconProps) {
  const SelectedIcon = allIcons[id];
  return SelectedIcon ? <SelectedIcon id={id} {...rest} /> : null;
}

Icon.defaultProps = {
  className: '',
  style: undefined,
  onClick: () => false,
};

export default Icon;
